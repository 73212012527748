<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <vuexy-logo/>
      </b-link>
      <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-0"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              fluid
              :src="imgUrl"
              alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Login-->
      <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >

          <b-card-text class="mb-2" v-html="settings.website_text"></b-card-text>
          <hr/>
          <div>
            <a  v-if="settings.facebook" :href="settings.facebook" target="_blank" class="mr-1">
              <b-button>
                <feather-icon
                    class="cursor-pointer"
                    icon="FacebookIcon"
                />
              </b-button>
            </a>
            <a  v-if="settings.linkedin" :href="settings.linkedin" target="_blank" class="mr-1">
            <b-button>
              <feather-icon
                  class="cursor-pointer"
                  icon="LinkedinIcon"
              />
            </b-button>
            </a>
            <a  v-if="settings.twitter" :href="settings.twitter" target="_blank" class="mr-1">
            <b-button>
              <feather-icon
                  class="cursor-pointer"
                  icon="XIcon"
              />
            </b-button>
            </a>
            <a  v-if="settings.instagram" :href="settings.instagram" target="_blank" class="mr-1">
              <b-button>
                <feather-icon
                    class="cursor-pointer"
                    icon="InstagramIcon"
                />
              </b-button>
            </a>
          </div>


          <!-- form -->
          <validation-observer
              ref="loginForm"
              #default="{invalid}"
          >
          </validation-observer>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>

/* eslint-disable global-require */
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from 'bootstrap-vue'
import {required, email} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import settingsService from "@/services/settingsService";

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      settings: '',
      sideImg: require('@/assets/images/login.jpg'),
      required,
      email,
    }
  },
  mounted() {
    this.getSettings()
  },
  computed: {

    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/login.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    getSettings() {
      this.loading = true
      settingsService.website().then(response => {
        this.settings = response.data.data[0]
        console.log(this.settings)
        console.log(this.settings.facebook)
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.img-fluid {
  max-width: 100%;
  border-bottom-left-radius: 7rem;
  border-top-right-radius: 7rem;
}
</style>
