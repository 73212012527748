import http from './httpService'

export default class AbstractService {
  endpoint = ''

  http = http

  constructor() {
    this.http = http
  }

  /**
   * get all resource data
   */
  index() {
    return this.http.get(this.endpoint)
  }

  /**
   * Get model by id
   */
  show(id, params) {
    return this.http.get(`${this.endpoint}/${id}`, { params })
  }

  post(data) {
    // define append form
    const formData = new FormData()
    for (const key in data) {
      formData.append(key, data[key])
    }
    return this.http.post(this.endpoint, formData)
  }

  put(id, data, params = null) {

    // define append form
    const formData = new FormData()

    for (const key in data) {
      formData.append(key, data[key])
    }
    return this.http.put(`${this.endpoint}/${id}`, formData, params)
  }

  /**
   * delete resource
   */
  delete(data) {
    return this.http.delete(`${this.endpoint}/${data}`)
  }
}
