import axios from '@axios'

function put(endpoint, data, params) {
  console.log(document.getElementById('lang'))
  return axios.post(endpoint, data, { params: { _method: 'PUT', ...params, lang: document.getElementById('lang') !== null ? document.getElementById('lang').value : '' } })
}

export default {
  get: axios.get,
  post: axios.post,
  put,
  delete: axios.delete,

}
